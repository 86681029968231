import { useState } from 'react';
import ServitechLogo from './svg/servitech-logo.svg'
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';



import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

const unsubscribeEndpoint = 'https://unsubscribe-express-service-staging-bjudfyf5za-uc.a.run.app/unsubscribeFromEmailList';

const App = () => {
    const { email } = useParams();
    const [hasUnsubscribed, setHasUnsubscribed] = useState(false);

    const handleUnsubscripe = async (e) => {
        e.preventDefault();
        const emailToUnsubscribe = email;
        console.log('true', emailToUnsubscribe);
        // do fetch to sql here
        const unsubscribeFetch = await fetch(unsubscribeEndpoint, { method: 'POST', body: JSON.stringify({ email: emailToUnsubscribe }), headers: { 'Content-Type': 'application/json' } });
        const unsubscribeTxt = await unsubscribeFetch.text();
        console.log(unsubscribeTxt);
        setHasUnsubscribed(true)
    }

    return (
        <>
            <div className='header'>
                <Row className='row-header'>
                    <Col xs={2} s={2} md={2} lg={2} xl={2}>
                        <a href="https://servitech.com/">
                        <img alt="ServiTech" className="logo" src={ServitechLogo} />
                        </a>
                    </Col>
                </Row>
                <Row className='page-header'>
                    <p ><b>ServiTech, Inc.</b></p>
                </Row>
            </div>

            {!hasUnsubscribed &&
                <div>
                    <Row className='page-message' style={{ paddingTop: "130px" }}>
                        <p>We're sorry to see you go! Are you sure you want to unsubscribe?</p>
                    </Row>
                    <Row className='page-message' style={{ paddingTop: "40px" }}>
                        <Col xs={3} s={3} md={3} lg={3} xl={3}>
                        </Col>
                        <Col xs={6} s={6} md={6} lg={6} xl={6}>
                            <Row>
                                <Button onClick={(e) => handleUnsubscripe(e)}>
                                    Confirm
                                </Button>
                            </Row>
                        </Col>
                        <Col xs={3} s={3} md={3} lg={3} xl={3}>
                        </Col>

                    </Row>
                </div>
            }
            {hasUnsubscribed &&
                <div>
                    <Row className='page-message' style={{ paddingTop: "130px" }}>
                        <span className="material-symbols-outlined" style={{color:"green", fontSize:"54pt"}}>
                            check_circle
                        </span>
                    </Row>
                    <Row className='page-message' style={{ paddingTop: "40px" }}>
                        <p>Thank you! You have successfully unsubscribed!</p>
                    </Row>
                </div>
            }
        </>
    )
}
export default App;